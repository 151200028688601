<template>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

    <div
        class="modal-header"
        style="border: none">
        <div class="contenidoCentrado">
        <h1
            class="tituloPrincipal text-center">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Aviso
            de Privacidad
        </h1>
        </div>

        <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        ></button>
    </div>

    <div
    class="modal-body"
    style="border: none">
        <div class="container">
            <div class="row">
                <div class="col">
                <p class="parraffoJustificado">
                    FERRING S.A. DE C.V. con
                    domicilio en la ciudad de
                    México en adelante "FERRING”,
                    informa la existencia de una
                    Política de Tratamiento de la
                    Información.
                </p>
                <p class="">
                    <b>Aviso de Privacidad</b>
                </p>
                <p class="parraffoJustificado">
                    Gracias por visitar el sitio
                    web. En Ferring, su privacidad
                    es importante para nosotros y
                    nuestras prácticas de
                    protección de datos se apegan
                    a lo dictado por las
                    regulaciones europeas y
                    nacionales/locales aplicables
                    de protección de datos.
                </p>
                <p class="parraffoJustificado">
                    Este Aviso de Privacidad le
                    brindará información sobre
                    cómo Ferring reúne, mantiene y
                    procesa sus datos personales y
                    describirá la manera en la
                    cual nos aseguramos de que sus
                    datos personales se vean
                    protegidos de acuerdo con las
                    regulaciones aplicables.
                </p>
                <p class="parraffoJustificado">
                    Este Aviso de Privacidad
                    solamente se vincula con este
                    sitio web; por lo tanto, si
                    visita otro sitio web
                    relacionado o recomendado por
                    Ferring consulte el aviso de
                    privacidad del sitio web
                    respectivo.
                </p>
                <p>
                    ¿Qué son los datos personales?
                </p>
                <p>
                    ¿Quién puede considerarse un
                    interesado?
                </p>
                <p>
                    ¿Cómo utiliza Ferring sus
                    datos personales?
                </p>
                <p>Cookies</p>
                <p>
                    Retención de datos personales
                </p>
                <p>Sus derechos</p>
                <p>
                    ¿Cómo puede Ferring compartir
                    o transferir sus datos
                    personales?
                </p>
                <p>
                    Vínculos con otros sitios web
                </p>
                <p>Información de seguridad</p>
                <p>
                    Menores de edad Modificaciones
                </p>
                <p>Información de contacto</p>
                <p>
                    ¿Qué son los datos personales?
                </p>
                <p class="parraffoJustificado">
                    Los datos personales son toda
                    aquella información
                    relacionada con un individuo
                    que lo identifica o lo hace
                    identificable. Entre estos se
                    encuentra: nombre, domicilio,
                    número telefónico o correo
                    electrónico o cualquier otra
                    información que pueda ser
                    usada para identificar su
                    individualidad.
                </p>
                <p>
                    ¿Quién puede considerarse un
                    interesado?
                </p>
                <p class="parraffoJustificado">
                    Un interesado es cualquier
                    individuo identificado o
                    identificable sobre quien son
                    procesados los datos
                    personales. Si usted no es un
                    interesado de Ferring tiene la
                    oportunidad de serlo al
                    visitar este sitio web y
                    proporcionar sus datos
                    personales de manera
                    voluntaria y así volverse un
                    interesado. Ferring solamente
                    reunirá sus datos personales
                    durante su visita en el sitio
                    web en caso de y cuando los
                    proporcione voluntariamente.
                </p>
                <hr />
                <p>
                    Categorías de datos personales
                    recabados y procesados.
                </p>
                <hr />
                <div class="table-responsive">
                    <table
                    class="table table-bordered"
                    >
                    <thead>
                        <tr>
                        <th scope="col">
                            Interesados
                        </th>
                        <th scope="row">
                            Categorías de Datos
                            Personales
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td scope="col">
                            Visitantes sitio web
                        </td>
                        <td>
                            <ul>
                            <li
                                class="parraffoJustificado"
                            >
                                Datos Generales:
                                nombre, domicilio,
                                fecha y lugar de
                                nacimiento,
                                nacionalidad,
                                estado civil,
                                sexo, profesión,
                                ocupación, número
                                de Registro
                                Federal de
                                Contribuyentes
                                (RFC), copia de la
                                Clave Única de
                                Registro de
                                Población (CURP),
                                datos o copia de
                                identificación con
                                fotografía.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Datos para
                                contactarlo:
                                dirección,
                                dirección de
                                correo
                                electrónico,
                                número de
                                teléfono, número
                                de whatsapp y de
                                fax.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Preferencias de
                                consumo, hábitos
                                de consumo de
                                productos o
                                servicios.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Educación y
                                habilidades:
                                Títulos
                                educativos.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Información de la
                                cuenta de usuario:
                                Contraseña de la
                                cuenta.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Información de
                                navegación: tiempo
                                de navegación,
                                información de
                                cookies, dirección
                                IP, historial de
                                interacción de
                                red, historial del
                                sitio web.
                            </li>
                            </ul>
                        </td>
                        </tr>
                        <tr>
                        <td
                            scope="col"
                            class="parraffoJustificado"
                        >
                            Profesionales de la
                            Salud
                        </td>
                        <td>
                            <ul>
                            <li
                                class="parraffoJustificado"
                            >
                                Datos Generales:
                                nombre, domicilio,
                                fecha y lugar de
                                nacimiento,
                                nacionalidad,
                                estado civil,
                                sexo, profesión,
                                ocupación, número
                                de Registro
                                Federal de
                                Contribuyentes
                                (RFC), copia de la
                                Clave Única de
                                Registro de
                                Población (CURP),
                                datos o copia de
                                identificación con
                                fotografía
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Datos para
                                contactarlo:
                                dirección,
                                dirección de
                                correo
                                electrónico,
                                número de
                                teléfono, número
                                de whatsapp y de
                                fax.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Preferencias de
                                consumo, hábitos
                                de consumo de
                                productos o
                                servicios.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Educación y
                                habilidades:
                                Títulos educativos
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Información de
                                empleo: división
                                de la unidad de
                                negocios, entidad
                                de la empresa,
                                puesto/función,
                                ubicación de la
                                oficina
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Información de la
                                cuenta de usuario:
                                Contraseña de la
                                cuenta.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Información de
                                navegación: tiempo
                                de navegación,
                                información de
                                cookies, dirección
                                IP, historial de
                                interacción de
                                red, historial del
                                sitio web.
                            </li>
                            </ul>
                        </td>
                        </tr>
                        <tr>
                        <td scope="col">
                            Clientes
                        </td>
                        <td>
                            <ul>
                            <li
                                class="parraffoJustificado"
                            >
                                Datos Generales:
                                nombre, domicilio,
                                fecha y lugar de
                                nacimiento,
                                nacionalidad,
                                estado civil,
                                sexo, profesión,
                                ocupación, número
                                de Registro
                                Federal de
                                Contribuyentes
                                (RFC), copia de la
                                Clave Única de
                                Registro de
                                Población (CURP),
                                datos o copia de
                                identificación con
                                fotografía.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Datos para
                                contactarlo:
                                dirección,
                                dirección de
                                correo
                                electrónico,
                                número de
                                teléfono, número
                                de whatsapp y de
                                fax.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Datos financieros:
                                cuenta bancaria.
                            </li>
                            <li
                                class="parraffoJustificado"
                            >
                                Datos fiscales:
                                Cédula de
                                identificación del
                                Sistema de
                                Administración
                                Tributaria (SAT).
                            </li>
                            <li>
                                Preferencias de
                                consumo, hábitos
                                de consumo de
                                productos o
                                servicios.
                            </li>
                            <li>
                                Información de la
                                cuenta de usuario:
                                Contraseña de la
                                cuenta.
                            </li>
                            <li>
                                Información de
                                navegación: tiempo
                                de navegación,
                                información de
                                cookies, dirección
                                IP, historial de
                                interacción de
                                red, historial del
                                sitio web.
                            </li>
                            </ul>
                        </td>
                        </tr>
                        <tr>
                        <td scope="col">
                            Empleados
                        </td>
                        <td>
                            <ul>
                            <li>
                                Datos de
                                identificación:
                                Nombre completo,
                                domicilio, fecha
                                de nacimiento,
                                imagen(, historia,
                                semejanza, sonido
                                de la voz, video
                                que puede ser
                                grabada en cintas
                                de audio o video,
                                preferencias
                                alimenticias,
                                copia de
                                identificación
                                oficial con
                                fotografía
                                (pasaporte,
                                credencial de
                                elector, cédula
                                profesional) copia
                                de acta de
                                nacimiento,) copia
                                del Registro
                                Federal de
                                Contribuyentes
                                (RFC), copia de la
                                Clave Única de
                                Registro de
                                Población (CURP),
                                documento que
                                acredite
                                afiliación al
                                Instituto Mexicano
                                del Seguro Social
                                (IMSS), número de
                                seguridad social
                                (NSS), firma.
                            </li>
                            <li>
                                Datos de contacto:
                                teléfono, correo
                                electrónico,
                                correo físico,
                                comprobante de
                                domicilio (recibos
                                de luz, teléfono o
                                predial).
                            </li>
                            <li>
                                Datos laborales:
                                lugar de empleo,
                                cargo y
                                currículum,
                                visa/permiso de
                                trabajo,
                                referencias
                                laborales, cartas
                                de recomendación,
                                puesto y sueldo de
                                anteriores
                                trabajos,
                                historial laboral.
                            </li>
                            <li>
                                Datos académicos:
                                Historia
                                académica,
                                certificados y
                                comprobantes de
                                estudio,
                                certificados y
                                constancias de
                                dominio de idiomas
                                y computación,
                                escolaridad,
                                título, cédula
                                profesional.
                            </li>
                            <li>
                                Datos relacionados
                                con su
                                personalidad,
                                capacidades
                                habilidades y
                                aptitudes: esta
                                información es
                                resultante de los
                                cuestionarios,
                                exámenes y
                                entrevistas
                                aplicados durante
                                el proceso de
                                reclutamiento y
                                selección; así
                                como de las
                                referencias
                                personales que
                                obtengamos sobre
                                ti.
                            </li>
                            <li>
                                Datos
                                patrimoniales:
                                sueldo y
                                prestaciones;
                                percepciones y
                                deducciones; copia
                                de la constancia
                                de baja o
                                modificación de
                                sueldo del
                                Instituto Mexicano
                                del Seguro Social;
                                copia de la
                                constancia de
                                percepciones del
                                último empleo;
                                información sobre
                                su cuenta de
                                INFONAVIT y
                                FONACOT resultados
                                de los estudios
                                socioeconómicos
                                que se lleven a
                                cabo durante el
                                proceso de
                                reclutamiento y
                                selección.
                            </li>
                            <li>
                                Datos de
                                identificación y
                                contacto de
                                terceros: datos de
                                identificación y
                                contacto de sus
                                beneficiarios o
                                dependientes
                                económicos, así
                                como de las
                                personas que
                                designe para ser
                                contactados en
                                caso de
                                emergencia, lo
                                cual puede incluir
                                nombre completo,
                                fecha de
                                nacimiento y otros
                                datos de
                                identificación,
                                tales como copias
                                de las actas de
                                nacimiento de
                                dependientes
                                económicos. Antes
                                de proporcionarnos
                                datos personales
                                de terceros,
                                asegúrese de
                                contar con su
                                consentimiento,
                                salvo en el caso
                                de aquellas
                                personas sobre las
                                que ejerza la
                                patria potestad o
                                tutela.
                            </li>
                            <li>
                                Datos financieros:
                                cuenta bancaria.
                            </li>
                            </ul>
                            <p>
                            Ferring puede tratar
                            los siguientes datos
                            personales que se
                            consideran datos
                            personales
                            sensibles:
                            </p>
                            <ul>
                            <li>
                                Datos de salud:
                                Historia clínica,
                                estudios,
                                exámenes,
                                consultas,
                                tratamientos y
                                pruebas médicas,
                                así como los
                                resultados de las
                                mismas, estado de
                                salud presente y
                                pasado.
                            </li>
                            <li>
                                Afiliación
                                sindical.
                            </li>
                            </ul>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <p class="parrafoJustificado">
                    ¿Cómo utiliza Ferring sus
                    datos personales?
                    </p>
                    <p>
                    Ferring International Center
                    S.A. (“Ferring”), como
                    auditor de datos en nombre
                    de las subsidiarias de
                    Ferring Group, solamente
                    procesará sus datos
                    personales cuando exista una
                    base legal para hacerlo y
                    cuando sea necesario cumplir
                    con el propósito para el
                    cual fueron recabados. La
                    base legal para el
                    procesamiento de sus datos
                    personales dependerá del
                    propósito para el cual
                    fueron recabados, como se
                    describe a continuación:
                    </p>
                    <ul>
                    <li
                        style="
                        list-style-type: upper-latin;
                        "
                    >
                        Los datos personales
                        pueden ser procesados si
                        es necesario para nuestros
                        intereses legítimos de
                        cumplir con los siguientes
                        objetivos (y bajo su
                        consentimiento o bien sea
                        necesario para mantener la
                        relación jurídica que
                        sostenemos con usted,
                        respectivamente y según
                        resulte aplicable en
                        términos de la ley local
                        ):
                        <ul>
                        <li
                            style="
                            list-style-type: disc;
                            "
                        >
                            Responder a su
                            consulta en las
                            siguientes categorías:
                            medios; asociaciones;
                            desarrollo de
                            relaciones
                            comerciales; y
                            cualquier otra
                            consulta general.
                        </li>
                        <li
                            style="
                            list-style-type: disc;
                            "
                        >
                            Procesar y responder a
                            consultas sobre
                            privacidad de datos y
                            sus derechos de
                            privacidad.
                        </li>
                        <li
                            style="
                            list-style-type: disc;
                            "
                        >
                            Procesar y evaluar los
                            casos reportados de
                            mala conducta
                            potencial y/o
                            violación de la
                            Filosofía de Ferring,
                            así como otras
                            políticas aplicables y
                            PNOs.
                        </li>
                        <li
                            style="
                            list-style-type: disc;
                            "
                        >
                            Procesar solicitudes
                            de vacantes actuales
                            y/o aceptar
                            solicitudes de empleo
                            no requeridas.
                        </li>
                        <li>
                            Procesar y responder a
                            una solicitud de
                            colaboración.
                        </li>
                        <li>
                            Cualquier otro
                            propósito de negocio
                            legítimo, tales como
                            análisis de datos,
                            auditoría, servicios
                            de mejoramiento y
                            expansión de nuestras
                            actividades
                            comerciales.
                        </li>
                        </ul>
                    </li>
                    </ul>
                    <p>
                    Ferring considera necesario
                    procesar los datos
                    personales con los fines
                    antes mencionados con base
                    en intereses legítimos, su
                    consentimiento o en la
                    relación jurídica que
                    mantenemos con Usted, para
                    asegurar que las consultas,
                    peticiones, solicitudes e
                    informes sean procesados a
                    tiempo y convengan a los
                    intereses de los usuarios,
                    políticas y leyes
                    aplicables, mientras que al
                    mismo tiempo se consideren
                    adecuadamente sus derechos.
                    Además, puede obtener más
                    información sobre la
                    doctrina jurisprudencial de
                    intereses legítimos que ha
                    ejecutado Ferring
                    </p>
                    <ul>
                    <li
                        style="
                        list-style-type: none;
                        margin: 0;
                        "
                    >
                        B. Los datos personales
                        pueden ser procesados
                        cuando sea necesario
                        cumplir con las leyes y
                        regulaciones aplicables,
                        tales como:
                        <ul>
                        <li>
                            Leyes dentro y fuera
                            de su país de
                            residencia.
                        </li>
                        <li>
                            Procesos legales o en
                            respuesta a un mandato
                            judicial para producir
                            ciertos datos
                            personales.
                        </li>
                        </ul>
                    </li>
                    </ul>

                    <p>
                    Los datos personales pueden
                    ser recabados y procesados
                    si es necesario para los
                    intereses vitales y para
                    cumplir con las obligaciones
                    de seguridad aplicables
                    dentro del sector
                    farmacéutico, tales como:
                    </p>
                    <ul>
                    <li>
                        Procesar un informe sobre
                        los efectos secundarios,
                        reacciones adversas al
                        fármaco, eventos adversos
                        u otra información de
                        seguridad en relación con
                        un fármaco/producto de
                        Ferring. Para obtener
                        información sobre el
                        procesamiento de datos de
                        categoría especial con
                        esta finalidad, véase el
                        Aviso de Privacidad de
                        Farmacovigilancia –
                        Pharmacovigilance Privacy
                        Notice.
                    </li>
                    </ul>
                    <li>
                    Los datos personales pueden
                    ser recabados y procesados
                    durante nuestras actividades
                    comerciales y educativas con
                    usted como Profesional
                    Sanitario. Refiérase a
                    nuestro Aviso de Privacidad
                    de Gestión de la Relación
                    con los Clientes sobre
                    aquellas actividades.
                    </li>
                    <p>
                    1.1 Destinatarios de
                    Campañas Educativas
                    </p>
                    <p>
                    A. Almacenar las historias
                    clínicas enviadas por los
                    profesionales de la salud
                    para dar cumplimento a las
                    normas sobre
                    farmacovigilancia y demás
                    normas aplicables en la
                    materia.
                    </p>
                    <p>
                    B. Transferir, bajo su
                    consentimiento, los Datos
                    Personales a terceras
                    personas en México y en el
                    exterior para su uso
                    comercial, en especial a los
                    aliados de FERRING que
                    otorgarán las Condiciones a
                    usuarios de Plan M u otras
                    campañas educativas, así
                    como para el agendamiento de
                    citas, llamadas y el
                    cumplimiento de las mismas.
                    </p>
                    <p>
                    C. Elaborar estudios de
                    analítica y estadística con
                    el fin de compartirlos con
                    subsidiarias, matrices,
                    empresas del grupo
                    empresarial o socios
                    vinculados al negocio de
                    FERRING.
                    </p>
                    <p>
                    D. Para publicarlos en las
                    cuentas de redes sociales
                    (Facebook, Twitter,
                    LinkedIn, Instagram, Tiktok,
                    Youtube, y cualesquiera
                    otras plataformas y/o red
                    social que de tiempo en
                    tiempo las partes consideren
                    oportunas) de FERRING, así
                    como asociadas al proyecto.
                    </p>
                    <p>
                    E. Para publicarlos en los
                    sitios web internos y
                    externos (Commsite/página
                    web de la compañía o el
                    proyecto) de FERRING o
                    asociados al proyecto.
                    </p>

                    <p>1.2 Usuarios</p>
                    <p>
                    A. Ser contactado
                    periódicamente mediante
                    mensajes de texto, correo
                    electrónico, correo físico,
                    vía telefónica, y cualquier
                    otro medio idóneo de
                    comunicación para brindarle
                    información y educación
                    general acerca de temas de
                    salud y calidad de vida e
                    invitarlo(a) a reuniones y
                    charlas educativas.
                    </p>
                    <p>
                    B. Contactarlos con el fin
                    de dar cumplimiento a las
                    normas aplicables en la
                    materia para productos
                    farmacéuticos, en especial
                    sobre farmacovigilancia.
                    </p>
                    <p>
                    C. Transmitir los datos
                    personales, a Encargados del
                    Tratamiento en México y en
                    el exterior cuando ello sea
                    necesario para dar
                    cumplimiento a las normas
                    sobre farmacovigilancia
                    aplicables en la materia.
                    </p>
                    <p>
                    D. Para publicarlos en las
                    cuentas de redes sociales
                    (Facebook, Twitter,
                    LinkedIn, Instagram, Tiktok,
                    Youtube, y cualesquiera
                    otras plataformas y/o red
                    social que de tiempo en
                    tiempo las partes consideren
                    oportunas) de FERRING, así
                    como asociadas al proyecto.
                    </p>
                    <p>
                    E. Para publicarlos en los
                    sitios web internos y
                    externos (Commsite/página
                    web de la compañía o el
                    proyecto) de FERRING o
                    asociados al proyecto.
                    </p>

                    <p>
                    1.3. Profesionales de la
                    Salud / Clínicas de
                    Fertilidad
                    </p>
                    <p>
                    A. Enviar información dar
                    cumplimento a las normas
                    sobre farmacovigilancia y
                    demás normas aplicables en
                    la materia.
                    </p>
                    <p>
                    B. Contactarlos con el fin
                    de dar cumplimiento a las
                    normas aplicables en la
                    materia para productos
                    farmacéuticos, en especial
                    sobre farmacovigilancia.
                    </p>
                    <p>
                    C. Transmitir los datos
                    personales, a Encargados del
                    Tratamiento en México y en
                    el exterior cuando ello sea
                    necesario para dar
                    cumplimiento a las normas
                    sobre farmacovigilancia
                    aplicables en la materia.
                    </p>
                    <p>
                    D. Ferring (o las empresas
                    que trabajan en nombre de
                    Ferring) utilizarán mi
                    información para comunicarse
                    con los profesionales de la
                    salud y/o clínicas de
                    fertilidad por teléfono,
                    correo electrónico o correo
                    postal para proporcionarme
                    información que pueda ser de
                    mi interés o para invitarme
                    a participar en una
                    investigación, iniciativas
                    educativas y de marketing,
                    esta es una finalidad
                    secundaria.
                    </p>
                    <p>
                        E. ingresar la información que
                        ha proporcionado en un base de
                        datos. Que esta información no
                        se mantendrá confidencial. Se
                        mostrará en uno o más sitios
                        web controlados por Ferring,
                        como parte del buscador de
                        clínicas para pacientes que
                        posiblemente busquen
                        tratamiento para la
                        infertilidad.
                    </p>
                    <p>
                    F. Para publicarlos en las
                    cuentas de redes sociales
                    (Facebook, Twitter,
                    LinkedIn, Instagram, Tiktok,
                    Youtube, y cualesquiera
                    otras plataformas y/o red
                    social que de tiempo en
                    tiempo las partes consideren
                    oportunas) de FERRING, así
                    como asociadas al proyecto.
                    </p>
                    <p>
                    G. Para publicarlos en los
                    sitios web internos y
                    externos (Commsite/página
                    web de la compañía o el
                    proyecto) de FERRING o
                    asociados al proyecto.
                    </p>

                    <p>
                    1.4 Proveedores/Prestadores
                    de Servicios
                    </p>
                    <p>
                    A. Para ejecutar los
                    contratos con los
                    proveedores de FERRING y
                    prestadores de servicios
                    contratados por FERRING.
                    </p>
                    <p>
                    B. Para llevar a cabo
                    operaciones de negocios.
                    </p>
                    <p>
                    C. Para referenciarlos y
                    compartir información con
                    otras personas naturales o
                    jurídicas.
                    </p>
                    <p>
                    D. Para publicarlos en las
                    cuentas de redes sociales
                    (Facebook, Twitter,
                    LinkedIn, Instagram, Tiktok,
                    Youtube, y cualesquiera
                    otras plataformas y/o red
                    social que de tiempo en
                    tiempo las partes consideren
                    oportunas) de FERRING, así
                    como asociadas al proyecto.
                    </p>
                    <p>
                    E. Para publicarlos en los
                    sitios web internos y
                    externos (Commsite/página
                    web de la compañía o el
                    proyecto) de FERRING o
                    asociados al proyecto.
                    </p>

                    <p>1.5 Clientes</p>
                    <p>Finalidades necesarias:</p>
                    <p>
                    Cumplir las obligaciones
                    contraídas por FERRING con
                    sus clientes y usuarios.
                    </p>
                    <p>
                    A. Enviar información sobre
                    cambios en las condiciones
                    de los Productos ofrecidos
                    por FERRING.
                    </p>
                    <p>
                    B. Tratar los datos de los
                    clientes y usuarios de
                    FERRING. suministrados por
                    terceros para las
                    finalidades aquí señaladas.
                    </p>
                    <p>
                    C. Transmitir los datos
                    personales, de los clientes
                    y usuarios de FERRING, a
                    Encargados del Tratamiento
                    en México y en el exterior
                    cuando ello sea necesario
                    para la ejecución de los
                    contratos con los clientes
                    por FERRING.
                    </p>
                    <p>
                    D. Los demás fines
                    necesarios para dar
                    cumplimiento a los contratos
                    suscritos con los clientes y
                    usuarios de FERRING.
                    </p>
                    <p>
                    Finalidades secundarias:
                    </p>
                    <p>
                    Enviar información sobre los
                    Productos que ofrece
                    FERRING.
                    </p>
                    <p>
                    Enviar al correo físico,
                    electrónico, celular o
                    dispositivo móvil, vía
                    mensajes de texto (SMS y/o
                    MMS) o a través de cualquier
                    otro medio análogo y/o
                    digital de comunicación
                    creado o por crearse,
                    información comercial,
                    publicitaria o promocional
                    sobre los Productos de
                    FERRING, eventos y/o
                    promociones de tipo
                    comercial o no de estas, con
                    el fin de impulsar, invitar,
                    dirigir, ejecutar, informar
                    y de manera general, llevar
                    a cabo campañas, promociones
                    o concursos de carácter
                    comercial o publicitario,
                    adelantados por FERRING y/o
                    por terceras personas.
                    </p>
                    <p>
                    Transferir, bajo su
                    consentimiento, los Datos
                    Personales a terceras
                    personas en México y en el
                    exterior a título gratuito u
                    oneroso para su uso
                    comercial.
                    </p>
                    <p>
                    Elaborar estudios de
                    analítica que incluyan
                    cualquier detalle
                    relacionado con los
                    Productos ofrecidos por
                    FERRING con el fin de
                    compartirlos con
                    subsidiarias, matrices,
                    empresas del grupo
                    empresarial o socios
                    vinculados al negocio de
                    FERRING
                    </p>
                    <p>1.6 Empleados</p>
                    <p>Finalidades necesarias:</p>
                    <p>
                    A. Para elaborar las hojas
                    de vida.
                    </p>
                    <p>
                    B. Para ejecutar y cumplir
                    con el contrato de trabajo y
                    demás obligaciones que como
                    empleador le corresponden
                    por ley.
                    </p>
                    <p>
                    C. Administrar y operar,
                    directamente o por conducto
                    de terceros, los procesos de
                    selección y vinculación de
                    personal, incluyendo la
                    evaluación y calificación de
                    los participantes y la
                    verificación de referencias
                    laborales y personales, y la
                    realización de estudios de
                    seguridad.
                    </p>
                    <p>
                    D. Desarrollar las
                    actividades propias de la
                    gestión de recursos humanos
                    tales como nómina,
                    afiliaciones a entidades del
                    sistema general de seguridad
                    social, actividades de
                    bienestar y salud
                    ocupacional, ejercicio de la
                    potestad sancionatoria del
                    empleador, entre otras.
                    </p>
                    <p>
                    E. Formar y preservar
                    expedientes médicos de
                    empleados para brindar
                    atención médica en el
                    entorno laboral.
                    </p>
                    <p>
                    F. Realizar los pagos
                    necesarios derivados de la
                    ejecución del contrato de
                    trabajo y/o su terminación,
                    y las demás prestaciones
                    sociales a que haya lugar de
                    conformidad con la ley
                    aplicable.
                    </p>
                    <p>
                    G. Contratar beneficios
                    laborales con terceros,
                    tales como seguros de vida,
                    gastos médicos, entre otros.
                    </p>
                    <p>
                    H. Notificar a contactos
                    autorizados en caso de
                    emergencias durante el
                    horario de trabajo o con
                    ocasión del desarrollo del
                    mismo.
                    </p>
                    <p>
                    I. Coordinar el desarrollo
                    profesional de los
                    empleados, el acceso de los
                    empleados a los recursos
                    informáticos y asistir en su
                    utilización.
                    </p>
                    <p>
                    J. Transmitir la información
                    de los empleados a
                    Encargados del Tratamiento
                    en México y en el exterior
                    cuando ello sea necesario
                    para el desarrollo de sus
                    operaciones y gestión de
                    nómina.
                    </p>
                    <p>
                        K. En los casos en que resulte
                        necesario, como, por ejemplo,
                        en caso de una emergencia
                        sanitaria (como una pandemia),
                        adoptar medidas de apoyo y
                        prevención sanitaria, así como
                        adoptar medidas de seguridad
                        sanitaria en el entorno
                        laboral, en cumplimiento a las
                        instrucciones de las
                        autoridades competentes, entre
                        ellas solicitar acudir a
                        atención médica e implementar
                        un protocolo de confinamiento,
                        según sea aplicable.
                    </p>
                    <p>
                    L. Con motivo de alguna
                    emergencia sanitaria,
                    detectar casos de
                    trabajadores considerados en
                    situación vulnerable para
                    tomar decisiones que atañen
                    al entorno laboral.
                    </p>
                    <p>
                    M. Implementar políticas
                    internas, de conformidad con
                    las leyes aplicables,
                    incluyendo sin limitar
                    políticas de seguridad de la
                    información, protocolos para
                    prevenir la discriminación y
                    atender casos de violencia,
                    acoso u hostigamiento
                    sexual, políticas para
                    proteger la salud mental de
                    los trabajadores, entre
                    otras.
                    </p>
                    <p>
                    N. Llevar a cabo
                    investigaciones de
                    violaciones a la ley y/o
                    supervisar el cumplimiento
                    de las políticas internas de
                    Ferring.
                    </p>
                    <p>
                    1.7 Finalidades aplicables a
                    todos
                    </p>
                    <p>Finalidades necesarias:</p>
                    <p>
                    A. Para el fortalecimiento
                    de las relaciones con sus
                    clientes y proveedores,
                    mediante el envío de
                    información relevante, la
                    toma de pedidos y evaluación
                    de la calidad de los
                    Productos de FERRING.
                    </p>
                    <p>
                    B. Para la determinación de
                    obligaciones pendientes, la
                    consulta de información
                    financiera e historia
                    crediticia y el reporte a
                    centrales de información de
                    obligaciones incumplidas,
                    respecto de sus deudores en
                    los términos de la ley
                    aplicable.
                    </p>
                    <p>
                    C. Controlar el acceso a las
                    oficinas de FERRING y
                    establecer medidas de
                    seguridad, incluyendo el
                    establecimiento de zonas
                    videovigiladas.
                    </p>
                    <p>
                    D. Para procurar la
                    integridad y seguridad de
                    nuestro personal, e
                    información.
                    </p>
                    <p>
                    E. Dar respuesta a
                    consultas, peticiones,
                    quejas y reclamos que sean
                    realizadas por los titulares
                    y transferir los Datos
                    Personales a organismos de
                    control y demás autoridades
                    que en virtud de la ley
                    aplicable deban recibir los
                    Datos Personales.
                    </p>
                    <p>
                    F. Registrar los datos
                    personales en los sistemas
                    de información de FERRING y
                    en sus bases de datos
                    comerciales y operativas.
                    </p>
                    <p>
                    G. Suministrar, compartir,
                    enviar o entregar sus datos
                    personales a empresas
                    matrices, filiales,
                    vinculadas, o subordinadas
                    de FERRING ubicadas en
                    México o en el extranjero en
                    el evento que dichas
                    compañías requieran la
                    información para los fines
                    aquí indicados.
                    </p>
                    <p>
                    H. Cumplir con las
                    obligaciones estatutarias y
                    voluntarias según la ley los
                    códigos de la industria; así
                    como ejercer o defender
                    nuestros derechos, incluso
                    ante las autoridades
                    competentes.
                    </p>
                    <p>
                    I. Notificarte sobre cambios
                    sustanciales a este aviso de
                    privacidad
                    </p>
                    <p>
                    Asimismo, podremos tratar
                    sus datos personales para
                    las siguientes finalidades
                    voluntarias, que no son
                    necesarias, pero nos
                    permiten y facilitan
                    brindarle una mejor
                    atención, si no se opone a
                    éstas a través de los medios
                    disponibles para ello y que
                    se describen más adelante en
                    la sección “Derechos ARCO y
                    Otros Derechos”:
                    </p>
                    <p>
                    A. Realizar encuestas sobre
                    nuestros servicios; entre
                    ellas, realizar encuestas
                    para el desarrollo de
                    estudios e investigaciones
                    de economía conductual.
                    </p>
                    <p>
                    B. Comunicarle nuestras
                    promociones y eventos,
                    mercadotecnia y publicidad.
                    </p>
                    <p>
                    C. Suscribirse a nuestro
                    boletín.
                    </p>

                    <p>
                    Sus datos personales
                    solamente serán usados para
                    la finalidad específica para
                    la cual fueron recabados y
                    no serán utilizados para
                    ningún otro propósito sin
                    notificarle anticipadamente
                    y/o obtener el
                    consentimiento apropiado.
                    </p>

                    <p>Cookies</p>

                    <p>
                    Cuando visite este sitio web
                    los datos sobre su uso, así
                    como datos que pueden ser
                    personales, podrían ser
                    recabados mediante Google
                    Analytics. Esta tecnología
                    de rastreo utiliza cookies
                    para reunir los datos sobre
                    el uso y actividad del sitio
                    web; por ejemplo: cuántos
                    usuarios visitan la página y
                    qué tan seguido. Estos datos
                    se pueden compartir con un
                    prestador de servicios
                    externo para mejorar
                    nuestros servicios.. El uso
                    de este tipo de tecnología
                    cumplirá con las
                    regulaciones aplicables de
                    protección de datos y con la
                    actual Regulación de
                    Privacidad electrónica.
                    </p>

                    <p>
                    Retención de datos
                    personales
                    </p>

                    <p>
                    Ferring implementará las
                    acciones apropiadas para
                    asegurar que sus datos
                    personales sean retenidos
                    solamente por el periodo
                    requerido para cumplir con
                    los propósitos señalados en
                    este Aviso de Privacidad.
                    Ferring solamente mantendrá
                    los datos personales por un
                    periodo más prolongado en
                    caso de ser requerido o
                    permitido por la
                    ley/regulación aplicable
                    dentro de la industria
                    farmacéutica. Es posible
                    deshabilitar las cookies en
                    cualquier navegador, a
                    través de las opciones de
                    configuración. A modo de
                    ejemplo, para deshabilitar
                    las cookies en ‘Google
                    Chrome’, debe ir a Menú –
                    Más herramientas – Borrar
                    datos de navegación – Borrar
                    todo – Aceptar. Tenga en
                    cuenta que al bloquear o
                    eliminar las cookies,
                    algunas funciones del sitio
                    pueden no funcionar
                    correctamente.
                    </p>

                    <p>Sus derechos</p>
                    <p>
                    En caso de y cuando sus
                    datos personales sean
                    recabados, conservados y
                    procesados, usted será
                    acreedor de derechos
                    específicos como interesado.
                    Ferring hará cumplir estos
                    derechos de conformidad con
                    las leyes europeas y
                    nacionales/locales
                    aplicables de protección de
                    datos y cuando las leyes de
                    farmacovigilancia lo
                    permitan. Sus derechos como
                    interesado serán los
                    siguientes:
                    </p>
                    <ul>
                    <li>
                        Derecho al acceso y
                        rectificación: tiene
                        derecho a solicitar una
                        copia de la información
                        que conservamos de usted,
                        así como a solicitar
                        correcciones de los datos
                        personales que conservamos
                        en caso de ser inexactos.
                    </li>
                    <li>
                        Derecho a eliminar y
                        restringir su
                        procesamiento: tiene
                        derecho a solicitar la
                        eliminación de sus datos
                        personales o a restringir
                        su procesamiento. Estos
                        derechos están limitados y
                        solamente serán
                        ejecutables en ciertas
                        circunstancias donde no se
                        aplican excepciones.
                    </li>
                    <li>
                        Derecho a la portabilidad
                        de datos: tiene derecho a
                        solicitar una copia de los
                        datos personales que
                        conservamos, así como el
                        derecho a solicitar que
                        estos datos sean
                        transferidos a otro
                        auditor. Este derecho está
                        sujeto a ciertas
                        condiciones y podría no
                        ser ejecutable; por
                        ejemplo, si no está
                        permitido por razones de
                        interés público o no es
                        técnicamente factible.
                    </li>
                    <li>
                        Derecho a objetar: tiene
                        derecho a objetar el
                        procesamiento de sus datos
                        personales. Si los datos
                        son procesados por razones
                        de interés público, con
                        fines científicos o
                        estadísticos usted todavía
                        puede objetar y será
                        nuestra responsabilidad
                        demostrar la legitimidad
                        del procesamiento de
                        datos.
                    </li>
                    <li>
                        Tiene derecho a solicitar
                        la limitación al uso o
                        divulgación de sus datos
                        personales, manifestar su
                        negativa a las finalidades
                        accesorias de datos
                        personales o bien, revocar
                        el consentimiento que nos
                        haya otorgado para el
                        tratamiento de estos.
                    </li>
                    <li>
                        Tiene derecho a contactar
                        o presentar una queja ante
                        su autoridad local
                        supervisora de protección
                        de datos.
                    </li>
                    </ul>
                    <p>
                    En caso de que desee
                    presentar una solicitud en
                    relación con los derechos
                    del interesado haga uso del
                    Formulario de Contacto del
                    Interesado –
                    <a
                        href="https://ferring.ethicspoint.eu/custom/ferring/forms/data/form_data.asp"
                        target="_blank"
                        rel="noopener noreferrer"
                    ></a>
                    Data Subject Contact Form.
                    </p>
                    <p>
                    ¿Cómo puede Ferring
                    compartir o transferir sus
                    datos personales?
                    </p>

                    <p>
                    Sus datos personales pueden
                    ser transferidos a otras
                    instalaciones de Ferring
                    (entre ellas, subsidiarias
                    de Ferring, contratistas,
                    socios o agentes), con las
                    bases legitimas para dicha
                    transferencia, como puede
                    ser una razón comercial
                    válida. Dichas ubicaciones
                    pueden incluir países fuera
                    del Espacio Económico
                    Europeo (EEE). Y cualquier
                    transferencia será
                    salvaguardada por los
                    acuerdos internacionales de
                    transferencia de datos para
                    asegurar que sus datos
                    personales estén protegidos
                    de conformidad con las leyes
                    europeas y
                    nacionales/locales
                    aplicables de protección de
                    datos.
                    </p>
                    <p>
                    En casos específicos Ferring
                    puede compartir sus datos
                    personales con terceros
                    (como proveedores,
                    contratistas, socios o
                    agentes) si es necesario
                    para cumplir con los
                    propósitos anunciados o si
                    dichos terceros asisten a
                    Ferring con el procesamiento
                    de datos. Ferring solamente
                    divulgará sus datos
                    personales a un tercero bajo
                    las siguientes
                    circunstancias:
                    </p>
                    <ul>
                    <li>
                        Usted ha proporcionado su
                        consentimiento pertinente.
                    </li>
                    <li>
                        Si aplica, como resultado
                        de su propia solicitud
                        como interesado.
                    </li>
                    <li>
                        Cuando exista un
                        fundamento legal para la
                        divulgación de datos y
                        usted ha recibido una
                        notificación apropiada al
                        respecto.
                    </li>
                    <li>
                        Cuando requerimos hacerlo
                        de conformidad con las
                        leyes aplicables o como
                        resultado de un fallo del
                        tribunal.
                    </li>
                    <li>
                        O bien, con su
                        consentimiento, cuando
                        Ferring decida vender,
                        comprar o fusionarse o de
                        otra manera reorganizar su
                        estructura comercial.
                    </li>
                    </ul>
                    <p>
                    Ferring de ningún modo
                    venderá su información a
                    ningún tercero.
                    </p>

                    <p>
                    Vínculos con otros sitios
                    web
                    </p>

                    <p>
                    Este sitio web puede
                    proporcionar vínculos con
                    sitios web relacionados o
                    externos. Ferring no se hace
                    responsable por el
                    contenido, calidad,
                    actividades de procesamiento
                    de datos o prácticas de
                    protección de datos de
                    cualquier otro sitio web. Si
                    usted visita otro sitio web
                    consulte el aviso de
                    privacidad y configuración
                    del sitio respectivo.
                    </p>

                    <p>
                    Información de seguridad
                    </p>

                    <p>
                    Ferring implementa
                    apropiadas medidas técnicas,
                    físicas y de seguridad
                    organizacional para
                    salvaguardar sus datos
                    personales del acceso no
                    autorizado, uso,
                    divulgación, destrucción o
                    pérdida accidental. Por
                    ejemplo, cuando es apropiado
                    Ferring hace uso de la
                    encriptación, anonimización
                    o seudonimización de datos
                    personales para conservarlos
                    con seguridad en un sistema
                    designado. Nuestras
                    políticas internas y
                    procedimientos están
                    diseñados para priorizar y
                    promover la protección de
                    datos personales.
                    </p>

                    <p>
                    Mientras que nuestras
                    medidas de seguridad de
                    datos son evaluadas y
                    actualizadas continuamente,
                    no puede garantizarse la
                    seguridad de los datos que
                    son transferidos por
                    internet desde una
                    computadora u otro
                    dispositivo y se le
                    recomienda seguir ciertos
                    pasos para protegerse en la
                    red y contra el uso no
                    autorizado de sus
                    contraseñas y dispositivos
                    móviles, así como la
                    instalación de un sistema
                    firewall apropiado,
                    antivirus y antispyware para
                    su computadora.
                    </p>

                    <p>
                    Posibles incidentes que
                    pueden ocurrir en relación
                    con los datos personales:
                    </p>

                    <ul>
                    <li>
                        La pérdida/robo de la
                        computadora personal.
                    </li>
                    <li>Hacker externo.</li>
                    <li>
                        Un correo electrónico con
                        datos personales enviado
                        por error.
                    </li>
                    <li>
                        Pérdida de información en
                        tránsito.
                    </li>
                    <li>
                        Documentos en un área no
                        asegurada.
                    </li>
                    </ul>
                    <p>
                    Si sospecha que la seguridad
                    está comprometida o detecta
                    vulnerabilidades, es
                    esencial reportarlo
                    inmediatamente mediante el
                    Formulario de Contacto del
                    Interesado –
                    <a
                        href="http://https://ferring.ethicspoint.eu/custom/ferring/forms/data/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Data Subject Contact
                        Form.</a
                    >
                    </p>

                    <p>Menores de edad</p>

                    <p>
                    Ferring está comprometido
                    con proteger la privacidad
                    de los niños. En caso de que
                    los datos personales
                    pertenezcan a un interesado
                    menor de 18 años (niños
                    pequeños según la ley
                    nacional aplicable) será
                    requerido el consentimiento
                    a su representante legal.
                    Ferring hará todo lo que
                    esté a su alcance por
                    verificar que haya sido
                    obtenido el consentimiento
                    necesario en dichos casos.
                    </p>

                    <p>Modificaciones</p>

                    <p>
                    Cualquier cambio
                    implementado en este Aviso
                    de Privacidad le será
                    comunicado en este lugar. Le
                    recomendamos revisar nuestro
                    Aviso de Privacidad cuando
                    visite nuestro sitio web
                    para estar al pendiente de
                    cualquier cambio y se
                    mantenga informado sobre
                    nuestro actual compromiso
                    con respetar el derecho a la
                    privacidad y brindar el más
                    alto nivel posible de
                    protección de datos.
                    </p>

                    <p>Información de contacto</p>

                    <p>
                    (i) Si desea optar por salir
                    o si tiene alguna pregunta o
                    comentario sobre este Aviso
                    de Privacidad no dude en
                    contactar con la entidad
                    jurídica de Ferring y al
                    buzón de Ferring.
                    </p>
                    <p>(ii) En caso de:</p>
                    <ul>
                    <li>
                        Reportar un incidente
                        ocurrido con los datos o
                        de sospechar de una
                        violación de datos
                        personales.
                    </li>
                    <li>
                        Presentar una solicitud
                        para ejercer sus derechos
                        como interesado.
                    </li>
                    <li>
                        Contactar con el Oficial
                        de Protección de Datos con
                        una consulta general.
                    </li>
                    </ul>
                    <p>
                    Favor de utilizar el
                    Formulario de Contacto del
                    Interesado –
                    <a
                        href="https://ferring.ethicspoint.eu/custom/ferring/forms/data/form_data.asp"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Data Subject Contact
                        Form.</a
                    >
                    </p>
                    <p>
                    Como alternativa envíenos un
                    correo a la siguiente
                    dirección:
                    </p>
                    <p>
                    (Atención: Oficial de
                    Protección de Datos)
                    </p>
                    <p>
                    Ferring International Center
                    S.A.
                    </p>
                    <p>
                    Chemin De la Vergognausaz 50
                    </p>
                    <p>1162 St Prex</p>
                    <p>Switzerland (Suiza)</p>

                    <p>
                    O con el Representante de la
                    UE de la GDPR,
                    </p>
                    <p>
                    (Atención: Oficial de
                    Protección de Datos)
                    </p>
                    <p>
                    Ferring International
                    PharmaScience Center (IPC)
                    </p>
                    <p>Amager Strandvej 405</p>
                    <p>2770 Kastrup</p>
                    <p>Denmark (Dinamarca)</p>

                    <p>
                    Con fundamento en la Ley
                    Federal de Protección de
                    Datos Personales, de su
                    Reglamento y de los
                    Lineamientos del Aviso de
                    Privacidad, se hace de su
                    conocimiento:
                    </p>
                    <p>
                    Nombre y domicilio del
                    Responsable: Ferring, S.A.
                    de C.V. (en adelante llamada
                    Ferring), con domicilio en
                    Av. Nemesio Diez Riega,
                    Manzana 2, Lote 15, No. 15,
                    Colonia Parque Industrial
                    Cerrillo II, C.P. 52000,
                    Lerma, Estado de México,
                    correo electrónico:
                    datos.personales@ferring.com
                    </p>

                    <p>
                    Este Aviso de Privacidad fue
                    actualizado el 21/03/2023
                    </p>
                </div>
                </div>
        </div>
        </div>
    </div>
    <div
        class="modal-footer"
        style="border: none"
    >
        <div class="container">
        <div class="row">
            <div
            class="col align-self-center"
            >
            <div class="contenidoCentrado">
                <button
                class="btnCerrarAviso rounded"
                data-bs-dismiss="modal"
                >
                CERRAR
                </button>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>
</template>

<script>
    export default {
        name : "AvisoPrivacidad",
    }
</script>

<style scoped>

    .tituloPrincipal {
        color: orange;
        color: #ff9900;
        font-family: "OpenSans-Bold";
        font-size: 40px;
    }

    .parraffoJustificado, li, p {
        font-family: "OpenSans-Regular";
        font-size: 15px;
        color: #707070;
        text-align: left;
        text-align: justify;
    }

</style>